import {
  DeleteOutlined,
  FolderOpenOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import CheckCircleTwoTone from '@ant-design/icons/lib/icons/CheckCircleTwoTone';
import CloseCircleTwoTone from '@ant-design/icons/lib/icons/CloseCircleTwoTone';
import DownloadOutlined from '@ant-design/icons/lib/icons/DownloadOutlined';
import FolderAddOutlined from '@ant-design/icons/lib/icons/FolderAddOutlined';
import UploadOutlined from '@ant-design/icons/lib/icons/UploadOutlined';
import {
  App,
  Button,
  Checkbox,
  CheckboxRef,
  Modal,
  Space,
  Spin,
  Table,
  Tooltip,
  Upload,
  theme,
} from 'antd';
import Search from 'antd/lib/input/Search';
import _ from 'lodash';
import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import { AgentContext, UserContext } from '../../App';
import { GroupAuthority } from '../../enums/GroupAuthority';
import { Role } from '../../enums/Role';
import { KAPTAIN_URL } from '../../services/config';
import {
  deleteKapsules,
  exportKapsule,
  getKapsules,
} from '../../services/KapsuleService';
import { Kapsule } from '../../types/Kapsule';
import IconFont from '../iconFont/IconFont';
import { KapsuleFieldsTable } from '../kapsuleFieldsTable/KapsuleFieldsTable';
const { useToken } = theme;
type KapsulesTableProps = {};

const kapsulesSearchTextFilter = (
  record: Kapsule,
  searchText: string
): boolean => {
  if (searchText === '') return true;
  return (
    record.name.toLowerCase().includes(searchText) ||
    record.table.toLowerCase().includes(searchText)
  );
};

export const KapsulesTable: FunctionComponent<KapsulesTableProps> = () => {
  const { token } = useToken();
  const { modal, message } = App.useApp();
  const agent = useContext(AgentContext);

  const user = useContext(UserContext);
  const [searchText, setSearchText] = useState<string>('');

  const [isShowResult, showResultBox] = useState<boolean>(false);
  const [fileListImportResult, setFileListImportResult] = useState<any>([]);
  const [isShowDeleteResult, showDeleteResultBox] = useState<boolean>(false);
  const [deleteListResult, setDeleteListResult] = useState<any>([]);

  const deleteTableEl = useRef<CheckboxRef>(null);

  const openKapsuleInfoModal = (record: Kapsule) => {
    modal.info({
      title: record.name,
      maskClosable: true,
      content: <KapsuleFieldsTable fields={record.fields} />,
      onOk() {},
    });
  };

  const iconFontShow = (database: Kapsule) => {
    if (database.database === 'kaptaindb') {
      return (
        <IconFont
          type={`icon-${database.database}`}
          style={{ fontSize: 19, color: token.colorPrimary }}
        />
      );
    }
    return (
      <IconFont type={`icon-${database.database}`} style={{ fontSize: 19 }} />
    );
  };

  const deleteConfirm = (
    record: Kapsule,
    cb: (deleteTable: boolean) => Promise<void>
  ) => {
    modal.confirm({
      title: 'Confirm',
      content: (
        <Space direction="vertical">
          <p>{`Are you sure to delete ${record.name}?`}</p>
          {record.database !== 'kaptaindb' && (
            <Checkbox ref={deleteTableEl}>
              Delete table on {record.database}
            </Checkbox>
          )}
        </Space>
      ),
      okText: 'Yes',
      cancelText: 'Cancel',
      onOk: async () => {
        const deleteTable = ((deleteTableEl.current as unknown) as CheckboxRef)
          ?.input?.checked;
        await cb(!!deleteTable);
      },
    });
  };

  const importPropsForOne = {
    name: 'file',
    accept: '.kap',
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    },
    showUploadList: false,
    beforeUpload: () => {
      return new Promise<void>((resolve, reject) => {
        modal.confirm({
          title: 'Confirm',
          content: (
            <div>
              <span>
                This operation will overwrite the{' '}
                {agent.template.kapsuleLabbel.sing}, do you want to continue?
              </span>
              <br />
              <span>
                Only the {agent.template.kapsuleLabbel.sing} structure will be
                changed. No data will be altered.
              </span>
            </div>
          ),
          okText: 'Yes',
          cancelText: 'Cancel',
          onOk: async () => {
            resolve();
          },
          onCancel: async () => {
            reject();
          },
        });
      });
    },
    onChange(info: any) {
      if (info.file.status === 'done') {
        message.success(`${info.file.name} import successfully`);
        reloadKapsules();
      } else if (info.file.status === 'error') {
        message.error(
          `${info.file.name} import failed, log: ${info.file.response.message}`
        );
      }
    },
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a: Kapsule, b: Kapsule) => a.name.localeCompare(b.name),
      render: (_text: string, record: Kapsule) => {
        return <Link to={`/data/kapsules/${record._id}`}>{_text}</Link>;
      },
    },
    {
      title: 'Database',
      dataIndex: 'database',
      render: (_text: string, record: Kapsule) => (
        <Space size="small">
          {iconFontShow(record)}
          {record.database === 'kaptaindb' ? (
            <span>{agent.template.kaptaindbLabel}</span>
          ) : (
            <span>{record.database}</span>
          )}
        </Space>
      ),
    },
    {
      title: 'Table Name',
      dataIndex: 'tableName',
      render: (_text: string, record: Kapsule) => (
        <Space size="small">
          {record.database === 'kaptaindb' ? (
            <i>default</i>
          ) : (
            <span>{_text}</span>
          )}
        </Space>
      ),
    },
    {
      title: 'ID',
      dataIndex: '_id',
      sorter: (a: Kapsule, b: Kapsule) => a._id.localeCompare(b._id),
    },
    {
      title: `Binding ${agent.template.kommentLabbel} Table`,
      dataIndex: 'table',
      sorter: (a: Kapsule, b: Kapsule) => a.table.localeCompare(b.table),
    },
    {
      title: 'Key Field',
      dataIndex: 'keyField',
      sorter: (a: Kapsule, b: Kapsule) => a.keyField.localeCompare(b.keyField),
    },
    {
      title: 'Owner',
      dataIndex: 'owner',
      sorter: (a: Kapsule, b: Kapsule) =>
        (a.owner || '').localeCompare(b.owner),
      render: (_text: string) => (
        <Tooltip title={_text}>
          {_text?.length > 18 ? _text.slice(0, 18) + '...' : _text}
        </Tooltip>
      ),
    },
    {
      title: 'Last Update',
      dataIndex: 'updatedAt',
      sorter: (a: Kapsule, b: Kapsule) => {
        const aTime = new Date(a.updatedAt).getTime() || 0;
        const bTime = new Date(b.updatedAt).getTime() || 0;
        return aTime - bTime;
      },
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: (_text: string, record: Kapsule) => (
        <Space size="middle">
          <Tooltip
            title={`Open ${_.upperFirst(agent.template.kapsuleLabbel.sing)}`}
          >
            <Link to={`/data/kapsules/${record._id}`}>
              <FolderOpenOutlined style={{ color: token.colorPrimary }} />
            </Link>
          </Tooltip>
          <Tooltip
            title={`${_.upperFirst(
              agent.template.kapsuleLabbel.sing
            )} Structure`}
          >
            <InfoCircleOutlined
              style={{ color: token.colorPrimary }}
              onClick={() => openKapsuleInfoModal(record)}
            />
          </Tooltip>
          {record.authority?.includes(GroupAuthority.DELETE) ||
          (user && user.role === Role.ADMIN) ? (
            <Tooltip
              title={`Delete ${_.upperFirst(
                agent.template.kapsuleLabbel.sing
              )}`}
            >
              <DeleteOutlined
                style={{ color: token.colorError }}
                onClick={() => {
                  deleteConfirm(record, async (deleteTable: boolean) => {
                    await deleteKapsules([record._id], deleteTable);
                    reloadKapsules();
                  });
                }}
              />
            </Tooltip>
          ) : null}
          <Tooltip
            title={`Export ${_.upperFirst(agent.template.kapsuleLabbel.sing)}`}
          >
            <DownloadOutlined
              style={{ color: token.colorPrimary }}
              onClick={() => {
                modal.confirm({
                  title: 'Confirm',
                  content: `Are you sure to export ${record.name}.kap?`,
                  okText: 'Yes',
                  cancelText: 'Cancel',
                  onOk: async () => {
                    await exportKapsule(record._id);
                  },
                });
              }}
            />
          </Tooltip>
          <Tooltip
            title={`Replace ${_.upperFirst(agent.template.kapsuleLabbel.sing)}`}
          >
            <Upload
              {...importPropsForOne}
              action={`${KAPTAIN_URL}/api/kapsule/import/kapsule/${record._id}`}
            >
              <UploadOutlined
                style={{ color: token.colorPrimary, cursor: 'pointer' }}
              />
            </Upload>
          </Tooltip>
        </Space>
      ),
    },
  ];

  const [kapsules, setKapsules] = useState<Kapsule[]>();
  const [selectedKapsuleIds, setSelectedKapsuleIds] = useState<string[]>([]);

  const onSearch = (searchText: string) => {
    if (searchText) {
      setSearchText(searchText.toLowerCase());
    } else {
      setSearchText('');
    }
  };

  const reloadKapsules = useCallback(async () => {
    try {
      const kapsules = await getKapsules();
      setKapsules(kapsules);
    } catch (e) {
      message.error(
        `${_.upperFirst(
          agent.template.kapsuleLabbel.pl
        )} load Failed! Check console for log`
      );
      console.error(e);
      setKapsules([]);
    }
  }, [message, agent]);

  useEffect(() => {
    reloadKapsules();
  }, [reloadKapsules]);

  const rowSelection = {
    onChange: (_selectedRowKeys: React.Key[], selectedRows: Kapsule[]) => {
      const selectedIds = selectedRows.map((item) => item._id);
      setSelectedKapsuleIds(selectedIds);
    },
  };

  const importProps = {
    name: 'files',
    accept: '.kap',
    action: `${KAPTAIN_URL}/api/kapsule/import/kapsules`,
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    },
    showUploadList: false,
    multiple: true,
    onChange: async (info: any) => {
      if (!isShowResult) showResultBox(true);
      if (info.file.status === 'done') {
        const res = {
          success: true,
          msg: `${info.file.name} import successfully`,
        };
        fileListImportResult.push(res);
        await setFileListImportResult([...fileListImportResult]);
      } else if (info.file.status === 'error') {
        const res = {
          success: false,
          msg: `${info.file.name} import failed, log: ${info.file.response.message}`,
        };
        fileListImportResult.push(res);
        await setFileListImportResult([...fileListImportResult]);
      }
    },
  };

  return kapsules ? (
    <div>
      <Table
        title={() => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <b>{_.upperFirst(agent.template.kapsuleLabbel.pl)}</b>
            <Space>
              <Upload {...importProps}>
                <Button icon={<FolderAddOutlined />}>Add</Button>
              </Upload>
              <Button
                disabled={selectedKapsuleIds.length === 0}
                danger
                onClick={() => {
                  modal.confirm({
                    title: 'Confirm',
                    content: `Are you sure to delete selected ${agent.template.kapsuleLabbel.pl}? (only records)`,
                    okText: 'Yes',
                    cancelText: 'Cancel',
                    onOk: async () => {
                      await deleteKapsules(selectedKapsuleIds)
                        .then(() => {
                          setSelectedKapsuleIds([]);
                          reloadKapsules();
                        })
                        .catch((err) => {
                          const kaps = JSON.parse(err.data && err.data.message);
                          if (kaps.length > 0) {
                            setDeleteListResult(kaps);
                            showDeleteResultBox(true);
                          }
                        });
                    },
                  });
                }}
              >
                Delete Selection
              </Button>
              <Search
                placeholder="input search text"
                allowClear
                onSearch={onSearch}
                style={{ width: 200 }}
              />
            </Space>
          </div>
        )}
        scroll={{ x: 'max-content' }}
        rowKey={(record) => record._id}
        columns={columns}
        dataSource={kapsules.filter((item) =>
          kapsulesSearchTextFilter(item, searchText)
        )}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
      />
      <Modal
        title="Import Result"
        open={isShowResult}
        footer={null}
        maskClosable={false}
        onCancel={() => {
          showResultBox(false);
          reloadKapsules();
          setFileListImportResult([]);
        }}
        styles={{ body: { maxHeight: '60vh', overflowY: 'auto' } }}
      >
        {fileListImportResult.map((e: any, i: number) => {
          if (e.success) {
            return (
              <p key={e.success + i}>
                <CheckCircleTwoTone twoToneColor={token.colorSuccess} /> {e.msg}
              </p>
            );
          } else {
            return (
              <p key={e.success + i}>
                <CloseCircleTwoTone twoToneColor={token.colorError} /> {e.msg}
              </p>
            );
          }
        })}
      </Modal>

      <Modal
        title="Failed To Delete"
        open={isShowDeleteResult}
        footer={null}
        onCancel={() => {
          showDeleteResultBox(false);
          setDeleteListResult([]);
        }}
        styles={{ body: { maxHeight: '60vh', overflowY: 'auto' } }}
      >
        <p>
          <CloseCircleTwoTone twoToneColor={token.colorError} /> You do not have
          delete permission for the following {agent.template.kapsuleLabbel.pl}:
        </p>
        {deleteListResult.map((e: any, i: number) => {
          return (
            <p key={e + i}>
              {i + 1}. {e}
            </p>
          );
        })}
      </Modal>
    </div>
  ) : (
    <div
      style={{
        height: 312,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Spin />
    </div>
  );
};
