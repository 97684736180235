import { App as AntdApp, ConfigProvider, Spin } from 'antd';
import axios from 'axios';
import { Suspense, createContext, useEffect, useState } from 'react';
import { Route, Routes, useNavigate, useSearchParams } from 'react-router-dom';
import agent, { AgentConfig } from './agents';
import './App.css';
import { Home } from './pages/home/Home';
import { Login } from './pages/login/Login';
import { autoLogin } from './services/AuthService';
import { User } from './types/User';
import { setFavicon } from './utils/setFavicon';

axios.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem('token');
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      sessionStorage.setItem('token', '');
      sessionStorage.setItem('user', '');
      window.location.href = '/login';
    }
    return Promise.reject(error.response);
  }
);

const { theme } = agent;

export const UserContext = createContext<User | undefined>(undefined);
export const AgentContext = createContext<AgentConfig>(agent);

function App() {
  const [user, setUser] = useState<User>();
  // const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, _] = useSearchParams();

  const doAutoLoginFromSearchParams = async (secret: string) => {
    try {
      const data = await autoLogin(secret);
      sessionStorage.setItem('token', data.accessToken);
      sessionStorage.setItem('user', JSON.stringify(data.user));
      handleLogin(data.user);
    } catch (error) {
      console.error('doAutoLogin', error);
      sessionStorage.setItem('token', '');
      sessionStorage.setItem('user', '');
      navigate('/login');
      return;
    }
  };

  useEffect(() => {
    setFavicon(agent.template.faviconHerf);
    document.title = agent.template.pageTitle;
  }, []);

  useEffect(() => {
    console.log('searchParams:', searchParams.get('autologin'));
    const autoLoginParams = searchParams.get('autologin');
    if (autoLoginParams) {
      doAutoLoginFromSearchParams(autoLoginParams);
      return;
    }
    const userData = sessionStorage.getItem('user');
    if (!userData) {
      //doAutoLogin();
      navigate('/login');
    } else {
      const user = (JSON.parse(userData) as unknown) as User;
      handleLogin(user);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(user)]);

  const handleLogin = (user: User) => {
    setUser(user);
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Layout: {
            triggerBg: theme.leftSideMenuBgColor,
          },
          Menu: {
            darkSubMenuItemBg: '#001529',
          },
        },
        token: {
          colorPrimary: theme.colorPrimary,
        },
      }}
    >
      <AgentContext.Provider value={agent}>
        <AntdApp>
          <div className="App">
            <Suspense fallback={<Spin size="large" />}>
              <Routes>
                <Route path="login" element={<Login onLogin={handleLogin} />} />
                {user && (
                  <Route
                    path="*"
                    element={
                      <UserContext.Provider value={user}>
                        <Home />
                      </UserContext.Provider>
                    }
                  />
                )}
              </Routes>
            </Suspense>
          </div>
        </AntdApp>
      </AgentContext.Provider>
    </ConfigProvider>
  );
}

export default App;
