import React, { useState } from 'react';
import { Button, FormInstance, Space, Typography } from 'antd';
import { CheckCircleTwoTone, WarningTwoTone } from '@ant-design/icons';
import { testConnection } from '../../../services/SettingCompService';

type TestConnectionBtnProps = {
  type: 'kaptaindb' | 'datasource';
  database: 'kaptaindb' | 'postgresql' | 'mssql' | 'mysql' | 'snowflake' | 'oracle';
  formRef: FormInstance;
};

const TestConnectionBtn: React.FC<TestConnectionBtnProps> = ({ type, formRef, database }) => {
  const [loading, setLoading] = useState(false);
  const [resultCode, setResultCode] = useState<'INITIAL' | 'SUCCEED' | 'FAILED'>();

  const handleClick = async () => {
    setLoading(true);

    try {
      if (type === 'datasource') {
        const formValues = formRef.getFieldsValue();
        const config = formValues[database];
        await testConnection({
          type,
          database,
          config,
        });
      } else if (type === 'kaptaindb') {
        const formValues = formRef.getFieldsValue();
        const config = formValues['db'];
        await testConnection({
          type: 'kaptaindb',
          database: config.KAPTAIN_DB,
          config,
        });
      }
      setResultCode('SUCCEED');
    } catch (error) {
      setResultCode('FAILED');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Space size={'small'}>
      <Button
        loading={loading}
        onClick={handleClick}
        danger={resultCode === 'FAILED'}
      >
        Test Connection
      </Button >
      {resultCode === 'SUCCEED' ?
        <>
          <CheckCircleTwoTone twoToneColor="#52c41a" />
          <Typography.Text type='success'>Connection successful</Typography.Text>
        </>
        : resultCode === 'FAILED' ?
          <>
            <WarningTwoTone twoToneColor="#FF4C4C" />
            <Typography.Text type='danger'>Connection failed</Typography.Text>
          </>
          : undefined}
    </Space>
  );
};

export default TestConnectionBtn;
